$primary: #87544e;
$enable-rounded: false;

@import 'bootstrap/scss/bootstrap';

body,
html,
#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

body {
    overflow-y: scroll;
}

.grecaptcha-badge {
    visibility: hidden;
}

.kf-header {
    &__logo {
        height: 50px;
    }
}

.kf-spinner {
    .spinner-border {
        width: 3rem;
        height: 3rem;
    }
}

.kf-top-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 10px;
    background-color: $primary;
    z-index: -1;
}